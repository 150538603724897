import React  from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { css } from '@emotion/react';
import ConditionalWrapper from '../components/conditional-wrapper';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';

export default class PortableText extends React.Component {
  constructor(props) {
    super(props);

    this.footnoteBlockCount = 0;
    this.footnoteCount = 0;

    this.serializers = {
      marks: {
        highlight: ({ children, mark }) => (
          <span
            css={css`
              color: var(--pink);
            `}
          >{children}</span>
        ),
        footnote: ({ children, mark }) => {
          this.footnoteCount++;

          return (
            <span>{children}<sup>{this.footnoteCount}</sup></span>
          )
        },
      },
      types: {
        block: (props) => {
          const footnotes = props.node.markDefs.filter((el) => el._type === 'footnote');

          if (props.node.style === 'indent') {
            return (
              <p
                className="indent"
                css={css`
                  grid-column-start: 4 !important;
                `}
              >{props.children}</p>
            )
          }

          if (props.node.style === 'small') {
            return (
              <p
                css={css`
                  font-size: 0.8em;
                `}
              >{props.children}</p>
            )
          }

          if (footnotes.length > 0) {
            return (
              <div
                css={css`
                  display: grid;
                  grid-template-columns: repeat(12, 1fr);
                  column-gap: var(--gutter);
                  row-gap: 0;
                  grid-column: span 12;
                  margin-bottom: 3.6rem;
                `}
              >
                {BlockContent.defaultSerializers.types.block(props)}
                <div
                className="type--small"
                css={css`
                  grid-column-start: 11;
                  grid-column-end: span 2;
                `}
                >
                  {footnotes.map((footnote, i) => {
                    this.footnoteBlockCount++;

                    return (
                      <div
                        css={css`
                          color: var(--pink);
                          margin-bottom: 1.5rem;
                        `}
                      >
                        {this.footnoteBlockCount}. {footnote.text}&nbsp;{footnote.link && <a
                          css={css`
                            color: var(--pink);
                            text-decoration: none;

                            &:hover {
                              color: var(--black);
                            }
                          `}
                          target="_blank"
                          rel="noreferrer"
                          href={footnote.link}
                        >↗</a>}
                      </div>
                    )}
                  )}
                </div>
              </div>
            )
          } else {
            return BlockContent.defaultSerializers.types.block(props);
          }
        },
        rule: ({ node }) => (
          <div
            css={css`
              width: 100%;
              height: 1px;
              background-color: var(--black);
              margin: 6rem 0;
            `}
          />
        ),
        button: ({ node }) => (
          <div
            css={css`
              text-align: center;
              margin: 2rem 0;
            `}
          >
            {node.type === 'link' && <a
              css={css`
                display: inline-block;
                text-decoration: none;
                border: 1px dotted var(--black);
                border-radius: 5rem;
                padding: 1.4rem 3rem 1.2rem;

                &:hover {
                  border: 1px solid var(--black);
                }
              `}
              target="_blank"
              rel="noreferrer"
              href={node.link}
            >{node.text}</a>}
            {node.type === 'file' && <a
              css={css`
                display: inline-block;
                text-decoration: none;
                border: 1px dotted var(--black);
                border-radius: 5rem;
                padding: 1.4rem 3rem 1.2rem;

                &:hover {
                  border: 1px solid var(--black);
                }
              `}
              target="_blank"
              rel="noreferrer"
              href={node.file?.asset.url}
            >{node.text}</a>}
            {node.type === 'embed' && <div
              css={css`
                display: inline-block;
                text-decoration: none;
                border: 1px dotted var(--black);
                border-radius: 5rem;
                padding: 1.4rem 3rem 1.2rem;
                cursor: pointer;

                &:hover {
                  border: 1px solid var(--black);
                }
              `}
              onClick={() => props.setEmbed(node.embed)}
            >{node.text}</div>}
          </div>
        ),
        bigButton: ({ node }) => (
          <div
            className={(node.inline) ? 'type--small' : ''}
            css={css`
              display: ${(node.inline) ? 'inline-block' : 'block'};
              text-align: center;
              margin: ${(node.inline) ? '2rem 3rem 5.6rem 0' : '5rem 0 8.6rem'};

              & > a, & > div {
                text-decoration: none;
                color: ${(node.inline) ? 'var(--black)' : 'var(--white)'};
                background-color: ${(node.inline) ? 'transparent' : ''};
                padding: ${(node.inline) ? '1.4rem 3rem 1.2rem' : '3rem 3rem 3.2rem'};
                border-radius: ${(node.inline) ? '5rem' : '10rem'};
                border-style: ${(node.inline) ? 'dotted' : 'solid'};

                &:hover {
                  color: ${(node.backgroundColor) ? node.backgroundColor.value : 'var(--black)'};
                  background-color: var(--white);
                  border-style: solid;
                }
              }
            `}
          >
            {node.type === 'link' && <a
              css={css`
                display: inline-block;
                background-color: ${(node.backgroundColor) ? node.backgroundColor.value : 'var(--black)'};
                border: 1px solid ${(node.backgroundColor) ? node.backgroundColor.value : 'var(--black)'};
              `}
              target="_blank"
              rel="noreferrer"
              href={node.link}
            >{node.text}</a>}
            {node.type === 'file' && <a
              css={css`
                display: inline-block;
                background-color: ${(node.backgroundColor) ? node.backgroundColor.value : 'var(--black)'};
                border: 1px solid ${(node.backgroundColor) ? node.backgroundColor.value : 'var(--black)'};
              `}
              target="_blank"
              rel="noreferrer"
              href={node.file?.asset.url}
            >{node.text}</a>}
            {node.type === 'embed' && <div
              css={css`
                display: inline-block;
                background-color: ${(node.backgroundColor) ? node.backgroundColor.value : 'var(--black)'};
                border: 1px solid ${(node.backgroundColor) ? node.backgroundColor.value : 'var(--black)'};
                cursor: pointer;
              `}
              onClick={() => props.setEmbed(node.embed)}
            >{node.text}</div>}
          </div>
        ),
        image: ({ node }) => {
          const gatsbyImageData = getGatsbyImageData(node?.asset._id, { layout: 'fullWidth' }, {
            projectId: process.env.SANITY_PROJECT_ID,
            dataset: process.env.SANITY_PROJECT_DATASET,
          })

          return (
            <figure
              css={css`
                margin-bottom: 4.5rem;
              `}
            >
              <GatsbyImage
                image={gatsbyImageData}
                loading="eager"
                alt={node.asset.altText}
                css={css`
                  width: ${(gatsbyImageData.height >= 1) ? '50' : '100'}%;
                `}
              />
              <figcaption className={`type--smaller ${(gatsbyImageData.height >= 1) ? 'align-center' : ''}`}>{node.asset.description}</figcaption>
            </figure>
          )
        },
        doubleImage: ({ node }) => {
          const gatsbyImageOneData = getGatsbyImageData(node.imageOne?.asset._id, { layout: 'fullWidth' }, {
            projectId: process.env.SANITY_PROJECT_ID,
            dataset: process.env.SANITY_PROJECT_DATASET,
          })

          const gatsbyImageTwoData = getGatsbyImageData(node.imageTwo?.asset._id, { layout: 'fullWidth' }, {
            projectId: process.env.SANITY_PROJECT_ID,
            dataset: process.env.SANITY_PROJECT_DATASET,
          })

          return (
            <div
              css={css`
                grid-column: span 12;
                display: grid;
                grid-template-columns: repeat(12, 1fr);
                gap: var(--gutter);
              `}
            >
              <figure
                css={css`
                  grid-column: span 6 !important;
                  margin-bottom: 4.5rem;
                `}
              >
                <GatsbyImage
                  image={gatsbyImageOneData}
                  loading="eager"
                  alt={node.imageOne?.asset.altText}
                />
                <figcaption className="type--smaller">{node.imageOne?.asset.description}</figcaption>
              </figure>
              <figure
                css={css`
                  grid-column: span 6 !important;
                  margin-bottom: 4.5rem;
                `}
              >
                <GatsbyImage
                  image={gatsbyImageTwoData}
                  loading="eager"
                  alt={node.imageTwo?.asset.altText}
                />
                <figcaption className="type--smaller">{node.imageTwo?.asset.description}</figcaption>
              </figure>
            </div>
          )
        },
        richImage: ({ node }) => {
          const gatsbyImageData = getGatsbyImageData(node.image?.asset._id, {maxWidth: 800}, {
            projectId: process.env.SANITY_PROJECT_ID,
            dataset: process.env.SANITY_PROJECT_DATASET,
          })

          return (
            <div
              css={css`
                display: inline-block;
                width: ${(node.width === 'half') ? '50%' : '100%'};
                vertical-align: middle;
                margin: 2rem 0;
              `}
            >
              <ConditionalWrapper
                condition={node.link}
                wrapper={children => <a target="_blank" rel="noreferrer" href={node.link}>{children}</a>}
              >
                <GatsbyImage
                  image={gatsbyImageData}
                  loading="eager"
                />
              </ConditionalWrapper>
            </div>
          )
        },
        embed: ({ node }) => {
          if (typeof document === 'undefined') {
            const iframe = node.code.match(/<iframe[^>]*>(.*?)<\/iframe>/)[0];

            return (
              <div
                css={css`
                  position: relative;
                  width: 100%;
                  padding-bottom: ${0.5645 * 100}%;
                  margin: 4.5rem 0;
                  
                  iframe {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                  }
                `}
                dangerouslySetInnerHTML={{__html: iframe}}
              />
            );
          } else {
            const element = document.createElement('div.iframe-wrapper');
            element.innerHTML = node.code;
            const iframe = element.querySelector('iframe');
            if (iframe) {
              let ratio = 0.5645;
              if (iframe.attributes.width?.value && iframe.attributes.height?.value) {
                if (!iframe.attributes.width.value.includes('%')) {
                  ratio = iframe.attributes.height.value / iframe.attributes.width.value;
                }
              }

              return (
                <div
                  css={css`
                    position: relative;
                    width: 100%;
                    margin: 4.5rem 0;
                    padding-bottom: ${ratio * 100}%;
                    
                    iframe {
                      position: absolute;
                      top: 0;
                      right: 0;
                      bottom: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                    }
                  `}
                  dangerouslySetInnerHTML={{__html: iframe.outerHTML}}
                />
              );
            } else {
              return null;
            }
          }
        },
        footnote: ({ node }) => {
          this.footnotes++;

          return (
            <a href={`#footnote-${this.footnotes}`}>[{this.footnotes}]</a>
          )
        },
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.blocks !== nextProps.blocks) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <BlockContent
        className={this.props.className}
        blocks={this.props.blocks}
        serializers={this.serializers}
        renderContainerOnSingleChild
      />
    )
  }
}
